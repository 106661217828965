<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <v-card>
      <v-form lazy-validation ref="form" @submit.prevent="submit">
        <v-card-title class="text-h5 grey lighten-2">
          <span class="text-h5">
            {{ $tc("locations.select_destinations_header", 1) }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="form">
              <v-col cols="12">
                <v-radio-group v-model="select_destinations">
                  <template v-slot:label>
                    <h3 class="mb-4">
                      {{ $t(`locations.select_destinations`) }}
                    </h3>
                  </template>
                  <v-radio class="my-3" value="all_destinations">
                    <template v-slot:label>
                      <div>{{ $t(`locations.all_destinations`) }}</div>
                    </template>
                  </v-radio>
                  <v-radio class="my-3" value="select-posibles-destinations">
                    <template v-slot:label>
                      <div>{{ $t("locations.select_destination") }}</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mb-1" color="grey darken-1" text @click="close">
            {{ $t("common.close") }}
          </v-btn>
          <v-btn
            class="mb-1"
            color="primary darken-1"
            type="submit"
            :disabled="!select_destinations"
          >
            {{ $t("common.next") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      select_destinations: "",
    };
  },
  methods: {
    ...mapActions({
      uploadCSVReference: "references/uploadCSVReference",
      updateReference: "references/updateReference",
    }),
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("select-destination-flow", this.select_destinations);
        this.close();
      }
    },
    open(item) {
      this.input = { ...item };
      this.dialog = true;
    },
    close() {
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
</script>
