<template>
  <v-dialog v-model="dialog" persistent max-width="1000px">
    <v-card>
      <v-form lazy-validation ref="form" @submit.prevent="submit">
        <v-card-title>
          <span class="text-h5" v-if="input.id">{{
            $t("locations.update")
          }}</span>
          <span class="text-h5" v-else>{{ $t("locations.add") }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <form class="d-flex flex-column">
              <v-row>
                <v-col cols="12" md="6" class="col-md-6">
                  <v-text-field
                    :label="$t(`locations.description`)"
                    outlined
                    v-model="input.description"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="col-md-6">
                  <v-select
                    v-model="input.country"
                    :items="countries"
                    item-text="title"
                    item-value="val"
                    :label="$t(`locations.country`)"
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="col-md-6">
                  <v-text-field
                    :label="$t(`locations.city`)"
                    outlined
                    v-model="input.city"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="col-md-6">
                  <v-text-field
                    :label="$t(`locations.pc`)"
                    outlined
                    v-model="input.postal_code"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" class="col-md-6">
                  <v-text-field
                    :label="$t(`locations.address`)"
                    outlined
                    v-model="input.street_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="col-md-6">
                  <v-text-field
                    :label="$t(`locations.number`)"
                    outlined
                    v-model="input.street_number"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="select-box col-md-6"
                  v-if="brands"
                >
                  <v-select
                    v-model="input.brands"
                    :items="brands"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$t(`locations.brand`)"
                    item-text="description"
                    item-value="id"
                    outlined
                    multiple
                    persistent-hint
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="col-md-6">
                  <v-select
                    v-model="input.first"
                    :items="select_first"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$t(`locations.first`)"
                    :item-text="`name`"
                    :item-value="`value`"
                    outlined
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
            </form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mb-1" color="grey darken-1" text @click="close">
            {{ $t("common.close") }}
          </v-btn>
          <v-btn class="mb-1" color="primary darken-1" type="submit">
            {{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/i18n";
export default {
  async mounted() {
    await this.fetchBrands();
  },
  data() {
    return {
      dialog: false,
      input: {},
      select_first: [
        { name: i18n.t("common.true"), value: true },
        { name: i18n.t("common.false"), value: false },
      ],
      countries: [
        { title: "Afganistan", val: "AF" },
        { title: "Islas de Åland", val: "AX" },
        { title: "Albania", val: "AL" },
        { title: "Argelia", val: "DZ" },
        { title: "Samoa Americana", val: "AS" },
        { title: "Andorra", val: "AD" },
        { title: "Angola", val: "AO" },
        { title: "Anguila", val: "AI" },
        { title: "Antartida", val: "AQ" },
        { title: "Antigua y Barbuda", val: "AG" },
        { title: "Armenia", val: "AM" },
        { title: "Afganistan", val: "AF" },
        { title: "Aruba", val: "AW" },
        { title: "Australia", val: "AU" },
        { title: "Austria", val: "AT" },
        { title: "Azerbayan", val: "AZ" },
        { title: "Afganistan", val: "AF" },
        { title: "Afganistan", val: "AF" },
        { title: "Bahamas", val: "BS" },
        { title: "Barein", val: "BH" },
        { title: "Bangladesh", val: "BD" },
        { title: "Barbados", val: "BB" },
        { title: "Turkia", val: "TR" },
        { title: "España", val: "ES" },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchBrands: "dashboard/fetchBrands",
      addLocation: "users/addLocation",
      updateUser: "users/updateUser",
    }),
    submit() {
      if (this.$refs.form.validate()) {
        const params = {
          description: this.input.description,
          address: {
            city: this.input.city,
            street_name: this.input.street_name,
            street_num: this.input.street_number,
            country: this.input.country,
            postal_code: this.input.postal_code,
            first: this.input.first,
          },
          brand_id: this.input.brands[0],
        };
        if (this.input.id) {
          this.$emit("update-location", this.input.id, params);
        } else {
          this.$emit("create-location", params);
        }
        this.close();
      }
    },
    open(item) {
      this.input = { ...item };
      this.dialog = true;
    },
    close() {
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters({
      brands: "dashboard/getBrands",
    }),
  },
};
</script>
