<template>
  <v-dialog v-model="dialog" persistent max-width="400px">
    <v-card>
      <v-form lazy-validation ref="form" @submit.prevent="submit">
        <v-card-title class="text-h5 grey lighten-2">
          <span class="text-h5">
            {{ $tc("locations.select_origins_header", 1) }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="form">
              <v-col cols="12" md="6" class="select-box col-md-6">
                <v-select
                  v-model="origins"
                  :items="locations"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t(`locations.select_origins_header`)"
                  item-text="description"
                  item-value="id"
                  outlined
                  multiple
                  persistent-hint
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mb-1" color="grey darken-1" text @click="close">
            {{ $t("common.close") }}
          </v-btn>
          <v-btn
            class="mb-1"
            color="primary darken-1"
            type="submit"
            :disabled="!locations"
          >
            {{ $t("common.next") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      origins: [],
      select_origins: "",
    };
  },
  methods: {
    ...mapActions({
      uploadCSVReference: "references/uploadCSVReference",
      updateReference: "references/updateReference",
    }),
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("origins", this.origins);
        this.close();
      }
    },
    open(locations) {
      this.locations = locations;
      this.dialog = true;
    },
    close() {
      this.$refs.form.reset();
      this.dialog = false;
    },
  },
};
</script>
