<template>
  <div>
    <div class="common-container">
      <v-form>
        <v-row
          class="mr-4 mt-4 d-inline-flex justify-space-between align-center"
          style="width: 100%"
        >
          <v-col cols="12" md="2"> </v-col>
          <v-col cols="12" md="2" class="d-flex"> </v-col>
        </v-row>
      </v-form>
      <h2 v-if="locationsTable">
        {{ $tc("locations.title", locationsTable.length) }}
      </h2>
      <div v-if="locationsTable">
        <v-data-table
          :headers="headers"
          :items="locationsTable"
          :items-per-page="5"
          class="elevation-0"
          :item-class="kill_classes"
          :hide-default-footer="locationsTable.length < 5"
        >
          <template v-slot:[`item.id`]="props">
            <router-link
              :to="{ name: 'location-detail', params: { id: props.item.id } }"
            >
              {{ props.item.id }}
            </router-link>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              text
              color="error"
              class="mx-3"
              small
              @click="confirmDeleteItem(item)"
            >
              <v-icon color="error">mdi-delete-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>
    <v-fab-transition>
      <v-btn
        fab
        fixed
        right
        bottom
        color="var(--v-primary-base)"
        class="add-user-btn"
        @click="$refs.createLocationDialogRef.open()"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <create-location-dialog
      ref="createLocationDialogRef"
      @create-location="createLocation"
    ></create-location-dialog>

    <select-origin-flow-dialog
      ref="selectOriginFlowDialogRef"
      @select-origin-flow="selectOriginFlow"
    ></select-origin-flow-dialog>

    <select-destination-flow-dialog
      ref="selectDestinationFlowDialogRef"
      @select-destination-flow="selectDestinationFlow"
    ></select-destination-flow-dialog>

    <origins-dialog ref="originsDialogRef" @origins="origins"></origins-dialog>

    <destinations-dialog
      ref="destinationsDialogRef"
      @destinations="destinations"
    ></destinations-dialog>

    <confirm-dialog ref="confirm"></confirm-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/i18n";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import CreateLocationDialog from "@/components/dialogs/CreateLocationDialog.vue";
import SelectOriginFlowDialog from "@/components/dialogs/SelectOriginFlowDialog.vue";
import SelectDestinationFlowDialog from "@/components/dialogs/SelectDestinationFlowDialog.vue";
import OriginsDialog from "@/components/dialogs/OriginsDialog.vue";
import DestinationsDialog from "@/components/dialogs/DestinationsDialog.vue";

export default {
  name: "locations",
  components: {
    CreateLocationDialog,
    ConfirmDialog,
    SelectOriginFlowDialog,
    SelectDestinationFlowDialog,
    OriginsDialog,
    DestinationsDialog,
  },
  async mounted() {
    await this.fetchLocationsTable({});
  },
  data() {
    return {
      params: {},
      headers: [
        {
          text: i18n.t("locations.id"),
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: i18n.t("locations.description"),
          value: "description",
        },
        {
          text: i18n.t("locations.country"),
          value: "address.country",
        },
        {
          text: i18n.t("locations.city"),
          value: "address.city",
        },
        {
          text: i18n.t("locations.address"),
          value: "address.street_name",
        },
        {
          text: i18n.t("locations.number"),
          value: "address.street_num",
        },
        {
          text: i18n.t("locations.pc"),
          value: "address.postal_code",
        },
        { text: i18n.tc("label.action", 2), value: "action" },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchTransits: "dashboard/fetchTransits",
      fetchLocationsTable: "locations/fetchLocationsTable",
      deleteLocation: "locations/deleteLocation",
      addLocation: "locations/addLocation",
    }),
    createLocation(params) {
      this.data_to_create = params;
      this.$refs.selectOriginFlowDialogRef.open();
    },
    selectOriginFlow(select) {
      if (select == "select_posibles_origins") {
        this.all_origins = false;
        this.$refs.originsDialogRef.open(this.locationsTable);
      } else {
        this.all_origins = true;
        this.$refs.selectDestinationFlowDialogRef.open();
      }
    },
    selectDestinationFlow(select) {
      if (select == "select-posibles-destinations") {
        this.all_destinations = false;
        this.$refs.destinationsDialogRef.open(this.locationsTable);
      } else {
        this.all_destinations = true;
        this.create_location();
      }
    },
    origins(locations) {
      this.create_origins = locations;
      this.$refs.selectDestinationFlowDialogRef.open();
    },
    destinations(locations) {
      this.create_destinations = locations;
      this.create_location();
    },
    create_location() {
      if (this.all_origins) {
        this.data_to_create.all_origins = true;
      } else {
        this.data_to_create.origins = this.create_origins;
      }
      if (this.all_destinations) {
        this.data_to_create.all_destinations = true;
      } else {
        this.data_to_create.destinations = this.create_destinations;
      }
      this.addLocation(this.data_to_create).then(() =>
        this.fetchLocationsTable(this.params)
      );
    },
    kill_classes(item) {
      console.log("item.kill: ", item.kill);
      if (item.kill) {
        return "kill-class";
      }
    },
    handleChange() {
      this.fetchLocationsTable(this.params);
    },
    async confirmDeleteItem(item) {
      if (
        await this.$refs.confirm.open(
          `${i18n.t("label.delete")}`,
          `${i18n.t("user.delete-message", { item: item.id })}`,
          this.options
        )
      ) {
        this.deleteRecord(item.id);
      }
    },
    deleteRecord(id) {
      this.deleteLocation(id)
        .then(() => {
          this.fetchLocationsTable();
        })
        .catch((err) => console.log("Delete location error :", err));
    },
  },
  computed: {
    ...mapGetters({
      locationsTable: "locations/getLocationsTable",
    }),
  },
};
</script>

<style></style>
